import React, { useState, useEffect } from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import { Link } from "gatsby";
import useWindowDimensions from "../utils/windowSize";
import Dialog from "@mui/material/Dialog";
import { styled, TextField, MenuItem } from "@mui/material";
import { ACTIVE_PAGE } from "../components/nav/utils";
import ShareButtons from "../components/sharebuttons";
import { isMp4 } from "../utils/functions";
import {
  CardTagButton,
  BlogHeaderImage,
  BlogHeaderVideo,
} from "../page-components/blog/styles";
import RequestForm from "../components/requestForm";
import ContactRequestContainer from "../page-components/blog/ContactRequestContainer/ContactRequestContainer";
import { kebabCase } from "lodash";

interface MediaComponentProps {
  mediaSrc: string;
  imageFocus?: string;
}

const MediaComponent: React.FC<MediaComponentProps> = ({
  mediaSrc,
  imageFocus = "",
}) => {
  if (!mediaSrc) return null;

  return isMp4(mediaSrc) ? (
    <BlogHeaderVideo loop autoPlay muted style={{ objectPosition: imageFocus }}>
      <source src={mediaSrc} type="video/mp4" />
    </BlogHeaderVideo>
  ) : (
    <BlogHeaderImage
      src={mediaSrc}
      alt=""
      style={{ objectPosition: imageFocus }}
    />
  );
};

interface DropdownProps {
  tabIndex: string;
  setTabIndex: React.Dispatch<React.SetStateAction<string>>;
}

const DropDownMenu: React.FC<DropdownProps> = ({ tabIndex, setTabIndex }) => {
  const handleChange = (event: any) => {
    setTabIndex(event.target.value);
  };
  const { width } = useWindowDimensions();

  return width && width < 650 ? (
    <StyledTextField
      select
      value={tabIndex}
      label="Change View"
      onChange={handleChange}
      className="dropDownMenu"
      InputLabelProps={{ style: { color: "#ffb310" } }}
      InputProps={{ style: { color: "#ffb310" } }}
    >
      <StyledMenuItem value="drone"> Photo Gallery </StyledMenuItem>
      <StyledMenuItem value="mapping"> Mapping </StyledMenuItem>
    </StyledTextField>
  ) : null;
};

interface GalleryProps {
  thumbnails: string[];
  images: string[];
  style?: React.CSSProperties;
}

const Gallery: React.FC<GalleryProps> = ({ thumbnails, images }) => {
  const [imagesIndex, setImagesIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const openImage = (index: number) => {
    setImagesIndex(index);
    setOpen(true);
  };

  return (
    <>
      <div className="aerial-drone__img-container">
        {thumbnails.map((img, i) => (
          <button
            key={i}
            className="aerial-drone__img-wrapper"
            onClick={() => openImage(i)}
          >
            <img
              src={img}
              alt=""
              className="aerial-drone__img"
              key={i}
              loading="lazy"
            />
          </button>
        ))}
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        PaperProps={{
          onClick: (e) => {
            if (e.target === e.currentTarget) setOpen(false);
          },
          style: {
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <img
          src={images[imagesIndex]}
          alt=""
          className="aerial-drone__img-lrg"
        />
      </Dialog>
    </>
  );
};

export default function AerialDronePage() {
  const { width } = useWindowDimensions();
  const [tabIndex, setTabIndex] = useState("mapping");

  const TabContent = () => {
    switch (tabIndex) {
      case "drone":
        return <Gallery thumbnails={thumbnailLinks} images={imageLinks} />;
      case "vr mesh":
        return (
          <iframe
            className="deliverable__iframe__vr"
            title="vr-drone-mesh"
            src="https://app.mapware.com/#/v?s=bdf1bb48-aeb5-4410-a8f0-f82709ed2f64"
            allowFullScreen
          />
        );
      case "mapping":
        return (
          <>
            <main className="content">
              <Gallery
                thumbnails={mappingThumbnails}
                images={mappingImages}
                style={{ width: "1050px" }}
              />
              <p>
                We deploy a variety of drones on-site to{" "}
                <b>standardize deliverables and save time on-site</b>. The
                efficiency translates to cost savings and consistent
                deliverables across your projects. Our drone workflows enhance
                the safety of our crews while collecting tough existing
                conditions that are hard to understand from the ground. Our
                comprehensive aerial and ground based capture is all done by
                experienced <b>FAA Part 107 UAS Drone Pilots</b>.
              </p>

              <p>
                Our drones are equipped with high-resolution cameras and other
                sensors that provide detailed visual and thermal imagery. This
                enables comprehensive data collection and analysis, allowing for
                a more accurate assessment of a construction project's progress
                or the condition of it's roof. <b>Drone Deploy Integrations</b>{" "}
                can be made available for <b>multi-site</b> roll-outs or{" "}
                <b>1-off adaptive reuse</b> projects. We capture close-up images
                and videos, making it easier to identify defects, structural
                issues, or potential problems on retail roofs or construction
                sites. This level of detail can inform better decision-making
                for repairs or improvements.
              </p>

              <p>
                <b>Digital records</b> of the construction process or the
                condition of a roof, can be invaluable for documentation,
                compliance, and insurance purposes. This record can serve as a
                historical reference. The <b>accessibility</b> and{" "}
                <b>versatility</b> of our aerial systems can capture access
                hard-to-reach areas that might be challenging for humans, such
                as roofs with steep pitches or tall structures. Their
                versatility allows them to adapt to various retail construction
                and inspection needs.
              </p>

              <p>
                <b>Smaller carbon footprint</b> compared to traditional
                inspection methods, such as using heavy machinery or manned
                aircraft. They contribute to more environmentally friendly
                operations. Data Analysis and Reporting: The data collected by
                drones can be processed through software and analyzed to
                generate reports and insights quickly. This data-driven approach
                improves decision-making and problem-solving in construction and
                inspection processes.
              </p>

              <p>
                <b>Scalability:</b> Whether it's a single retail store or a
                multi-site retail development, drones can adapt to the
                requirements. Incorporating drone documentation offers a 3D
                technological edge that enhances safety, efficiency, and
                precision across the adaptive re use industry. By harnessing the
                power of aerial technology, businesses can streamline their
                operations, reduce costs, and ensure the quality and longevity
                of their structures. We make construction documentation drones{" "}
                <b>available in all markets.</b>
              </p>

              <p>
                Our imaging can produce <b>3D, orthomosaic,</b> and{" "}
                <b>thermal</b>. Each of these aerial maps have many benefits
                from
                <b> pre-planning, monitoring progress </b> and{" "}
                <b>understanding a site’s layout</b>, 3-5 hours after a site
                visit. This level of data is precise{" "}
                <b>compared to satellite imaging or plane imaging</b>.
              </p>

              <img
                className="img"
                src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2FHow_Do_Drones_Work_in_LiDAR_and_Robotic_Imaging.png?alt=media&token=67cf9621-bd90-46dc-8f6d-6e92c7202410"
                alt=""
                loading="lazy"
              />
            </main>
          </>
        );
      default:
        return null;
    }
  };
  const [url, setUrl] = useState("");
  const tags = ["As-Built Documentation", "Drone", "Photogrammetry"];
  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.AERIALDRONE}>
      <Seo
        title="Standard Drone Deliverables"
        description="Sample Aerial Drone services provided by Robotic Imaging."
      />

      <header className="header">
        <h2 className="blog-post__header blog-post__header--no-margin">
          Standard Drone Deliverables
        </h2>
      </header>

      <main className="content">
        <MediaComponent mediaSrc="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/blog-images%2FStandard_Drone_Deliverables_Cover.png?alt=media&token=166d1a79-e729-4440-b64d-de7d0e1d6c87" />
        <br />
        <small>
          {tags.map((tag, i) => (
            <CardTagButton key={tag}>
              <small>
                <Link
                  className="blog-post__tag-link"
                  to={`/tags/${kebabCase(tag)}/`}
                >
                  {tag}
                </Link>
                {i < tags.length - 1 ? "" : ""}
              </small>
            </CardTagButton>
          ))}
        </small>
        <small className="small_text">Robotic Imaging</small>
        <small className="small_text">March 24, 2024</small>

        <small style={{ paddingTop: "10px" }}>
          <ShareButtons
            title={"Standard Drone Deliverables"}
            url={url}
            tags={tags}
          />
        </small>

        <DropDownMenu tabIndex={tabIndex} setTabIndex={setTabIndex} />

        {/* Only display tab buttons on screens larger than 650 px */}
        {width && width >= 650 ? (
          <div className="site__header__button-container aerial-drone__tab-container">
            <button
              key="mapping"
              onClick={() => setTabIndex("mapping")}
              disabled={tabIndex === "mapping"}
              className={`site__header__button${
                tabIndex === "mapping" ? "-disabled" : ""
              }`}
            >
              Mapping
            </button>
            <button
              key="vr mesh"
              onClick={() => setTabIndex("vr mesh")}
              disabled={tabIndex === "vr mesh"}
              className={`site__header__button${
                tabIndex === "vr mesh" ? "-disabled" : ""
              } aerial-drone__hide-small`}
            >
              VR Mesh
            </button>
            <button
              key="photos"
              onClick={() => setTabIndex("drone")}
              disabled={tabIndex === "drone"}
              className={`site__header__button${
                tabIndex === "drone" ? "-disabled" : ""
              }`}
            >
              Photo Gallery
            </button>
          </div>
        ) : null}
        <TabContent />
        <div
          className="page-navigation"
          style={{ flexDirection: "column", borderBottom: "none" }}
        >
          <ContactRequestContainer />
        </div>
      </main>
    </SiteWrapper>
  );
}

const thumbnailLinks = [
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2Fimage%20(1).png?alt=media&token=acb97f2f-577c-4e28-a4b0-1a1fd74169d1",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(4%20of%205).jpg?alt=media&token=1ec0d250-48a2-4eff-9580-719f2b56daed",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(5%20of%205).jpg?alt=media&token=e44f63ad-330e-44be-96dd-cc71fbd348f0",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(2%20of%205).jpg?alt=media&token=697a3fb0-8460-4e23-848a-a2b7f6a4e529",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDrone%20Map%203.23.22.png?alt=media&token=dba2640a-74d0-4267-8f76-73bd4699f709",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2F165%20Ontario%20St%20-%201.5.2021%20(45%20of%2086).jpg?alt=media&token=f6262d74-c461-41ff-85c4-4ac5fb586fff",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FComet%207.12.19-4.jpg?alt=media&token=a358d068-0767-4d05-8901-8d6afaae18b5",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2Fbestlaserscan.jpeg?alt=media&token=6a83cd02-e3e6-429b-80ea-04099ee75cf9",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2Flaserscanningcompany.jpeg?alt=media&token=ac0e073c-3e59-4948-b982-24184ea61849",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2Flaserscanningphiladelphia.jpeg?alt=media&token=4d1d6408-c481-4df1-aaef-23f18dc013f0",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2Fphillybydrone.jpeg?alt=media&token=87a30c29-8d03-4bf8-9c96-cf0578250762",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2Froboticimagingm300.jpeg?alt=media&token=e26338ae-4b3d-46b4-a955-815b0a8c4e4b",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2Fscantocadbim.jpeg?alt=media&token=cf40302f-3147-4d1b-9bb6-a4ce40fd773c",
  // "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(1%20of%205).jpg?alt=media&token=0f865569-1280-451e-b6f7-8d45240c848c",
  // "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(3%20of%205).jpg?alt=media&token=897539fc-d4dc-4e81-b819-e8bbeb5c4535",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2F6mP58o_g.png?alt=media&token=f8aa0dee-b80e-4387-ab24-7500275fbe49",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FDJI_0358(200x150).jpg?alt=media&token=28ae4c67-76e8-41e2-a8e5-e0b947399926",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FDJI_0076(200x150).JPG?alt=media&token=54521eac-71e8-4b7e-a89f-5ef5af8101cb",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FDJI_0036(200x150).JPG?alt=media&token=5f73bbc1-a53d-4d5a-b59b-711b7dcc0697",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FDJI_0939(200x150).JPG?alt=media&token=e8ee595a-ee04-4059-abe8-5032914dffa3",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FDJI_0035(200x150).JPG?alt=media&token=3c58c101-97f4-49de-863f-2c3f41587565",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FDJI_0753(200x150).JPG?alt=media&token=59140ca9-cea3-43bf-993f-e700569e9668",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fthumbnails%2FDJI_0285(200x150).jpg?alt=media&token=f62fc273-da60-4ab2-a5a8-04fe70c7c996",
];

const imageLinks = [
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fimage%20(1).png?alt=media&token=f246a13d-8c88-4953-a7fb-dc1767423184",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(4%20of%205).jpg?alt=media&token=e185ec40-908c-4528-80b5-840248a0377c",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(5%20of%205).jpg?alt=media&token=a51a47c7-467a-40fd-96b6-bc368340c8ad",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(2%20of%205).jpg?alt=media&token=83e7978b-3f3d-4f90-b295-dd6063d2bc0e",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDrone%20Map%203.23.22.png?alt=media&token=dba2640a-74d0-4267-8f76-73bd4699f709",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2F165%20Ontario%20St%20-%201.5.2021%20(45%20of%2086).jpg?alt=media&token=ec45eb3f-c8a2-4f44-a915-87ae29ebb082",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FComet%207.12.19-4.jpg?alt=media&token=14c86693-5b80-498c-ace1-2cdf9692f409",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fbestlaserscan.jpeg?alt=media&token=efc791e6-1b2b-4dd2-8830-587999364265",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Flaserscanningcompany.jpeg?alt=media&token=675a477a-393f-4d43-ae4c-a9e78015983c",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Flaserscanningphiladelphia.jpeg?alt=media&token=394b4c91-30bb-4996-9f56-8a02edbb1b66",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fphillybydrone.jpeg?alt=media&token=20f3a35c-3913-4746-b039-4bef57be1e2f",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Froboticimagingm300.jpeg?alt=media&token=66d25995-4841-4eb0-9e91-df055922df1c",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fscantocadbim.jpeg?alt=media&token=742b3898-566a-433d-9f9b-ce69fce7db2a",
  // "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(1%20of%205).jpg?alt=media&token=8ac12383-2e2b-4a73-8270-67c4adc57dbb",
  // "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FBroad%20St%20Historic%20Preservation%20-%20Facade%20Restoration%20Inspection%20(3%20of%205).jpg?alt=media&token=3f0af7eb-b3c0-478b-950b-fc64830b4ffd",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2F6mP58o_g.png?alt=media&token=5221589d-0899-4432-8d48-eb3e99c2d343",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDJI_0358.jpg?alt=media&token=7dd1de5f-baf9-48b6-ba72-12625e4a802e",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDJI_0076.JPG?alt=media&token=afa34f34-2892-4f8c-abaf-7ed7052ecdcb",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDJI_0036.JPG?alt=media&token=5cc31e61-abe1-4191-9f66-34e019716fcc",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDJI_0939.JPG?alt=media&token=7162f9fb-0198-4aec-a528-de6c58546c6a",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDJI_0035.JPG?alt=media&token=50fd26df-5fc9-4e74-b633-91f5a2e8fb11",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDJI_0753.JPG?alt=media&token=d698205c-c116-4ce1-8fd3-aaad73472c51",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2FDJI_0285.jpg?alt=media&token=0cfb3c93-7099-4540-b956-1e576cd340ff",
];

const mappingThumbnails = [
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2FDJI_0267(200x167).JPG?alt=media&token=52466bcf-7d69-4ebd-9438-b5e3e63f7c7d",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2Fdji_fly_20221213_164022_395_1671231709574_aeb(200x113).jpg?alt=media&token=588ebbb7-c29e-4b1a-8efb-d063f9c9a4bf",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2FDJI_0187(200x113).JPG?alt=media&token=82a20a09-ccf8-4fe0-aa12-a507757108a8",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2Fdji_fly_20221210_164714_371_1670710057997_photo(200x113).jpg?alt=media&token=eda2a175-49de-4bb9-9133-5af8e70b524b",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2FResized-Untitled-2(200x134).jpg?alt=media&token=e920b97b-8c25-4873-9c9b-fb9a4ac40d43",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2FResized-Untitled-3(200x133.33).jpg?alt=media&token=eb401425-93fe-4782-afa2-dbc52c540cf3",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2FResized-Untitled-3(200x151).jpg?alt=media&token=25877c7b-be84-44b7-baa6-5ac55b1813a5",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fthumbnails%2FResized-Untitled-3(200x149).jpg?alt=media&token=d35f6bbd-d8c7-4f9b-91ab-a24dbacaaf23",
];

const mappingImages = [
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2FDJI_0267.JPG?alt=media&token=7949bacd-111c-4072-9a08-5a91c5b64cbf",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fdji_fly_20221213_164022_395_1671231709574_aeb.jpg?alt=media&token=8f2e49e3-d953-4cad-8bda-f61a68e227b5",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2FDJI_0187.JPG?alt=media&token=7264dd46-eb4e-4fbf-8136-c5a40db192a3",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2Fdji_fly_20221210_164714_371_1670710057997_photo.jpg?alt=media&token=5306e220-952f-47b4-a8f0-dda64b5f239b",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2FDJI_0972.JPG?alt=media&token=109639af-04f7-4789-a439-19ade861edeb",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2FDJI_0943.JPG?alt=media&token=eb022574-c630-41ec-afa4-83be5de149cf",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2FDJI_0634.JPG?alt=media&token=0b695c84-b9a0-40d2-b1df-95fd718757bc",
  "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Faerial-drone%2Fmapping%2FDJI_0630.JPG?alt=media&token=31b742ea-7d24-4ea4-a8e0-867b8e8d7fe2",
];

const StyledTextField = styled(TextField)`
  margin-top: 20px;
  color: #ffb310;
  font-family: Helvetica;
  font-weight: 400px;
  width: 240px;
  margin: 0 auto 10px;
  display: flex;

  > * {
    font-size: 15.5px !important;
  font-family: Helvetica;
  }

  > .MuiInputBase-root {
    > .MuiSvgIcon-root {
      color: #ffb310;
    }
    > .MuiOutlinedInput-notchedOutline {
      border-color: #ffb310;
    }
  }

  &:hover {
    > .MuiInputBase-root {
      > .MuiSvgIcon-root > .MuiOutlinedInput-notchedOutline {
        border-color: #ffb310;
        border-width: 2px;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 15px;
`;
